import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/HelloWorld",
        name: "HelloWorld",
        component: () => import("@/components/HelloWorld.vue"),
    },
    {
        path: "/LoginPage",
        name: "LoginPage",
        component: () => import("@/components/login/LoginPage.vue"),
    },
    {
        path: "/AdminMainPage",
        name: "AdminMainPage",
        component: () => import("@/components/admin/AdminMainPage.vue"),
    },
    {
        path: "/Test",
        name: "Test",
        component: () => import("../components/TestModule.vue"),
    },
    {
        path: "/AdminLog",
        name: "AdminLog",
        component: () => import("../components/admin/AdminLogPage.vue"),
    },
    {
        path: "/PlayerPage",
        name: "PlayerPage",
        component: () => import("../components/player/PlayerPage.vue"),
    },
    {
        path: "/Attendance",
        name: "Attendance",
        component: () => import("../components/attendance/AttendancePage.vue"),
    },
    {
        path: "/WorkoutKiosk",
        name: "WorkoutKiosk",
        component: () => import("../components/kiosk/KioskPage.vue"),
    },
    {
        path: "/AdminCenterMobile",
        name: "AdminCenterMobile",
        component: () => import("../components/admin/AdminCenterMobile.vue"),
    },
    {
        path: "/MobileAdmin",
        name: "MobileAdmin",
        component: () => import("../components/MobileAdmin/MobileAdminMainPage.vue"),
        children: [
            {
                path: "",
                name: "MobileAdminAttendancePage",
                component: () => import("../components/MobileAdmin/MobileAdminAttendancePage.vue"),
            },
            {
                path: "NotificationPage",
                name: "MobileAdminNotificationPage",
                component: () => import("../components/MobileAdmin/MobileAdminNotificationPage.vue"),
            },
            {
                path: "TodoPage",
                name: "MobileAdmin/MobileAdminTodoPage",
                component: () => import("../components/MobileAdmin/MobileAdminTodoPage.vue"),
            },
            {
                path: "SettingPage",
                name: "MobileAdminSettingPage",
                component: () => import("../components/MobileAdmin/MobileAdminSettingPage.vue"),
            },
            {
                path: "LoginPage",
                component: () => import("../components/MobileAdmin/MobileAdminLoginPage.vue"),
            },
        ],
    },
    {
        path: "/AdminMakePhotoCard",
        name: "AdminMakePhotoCard",
        component: () => import("@/components/admin/imageGenerator/AdminMakePhotoCard.vue"),
    },
    {
        path: "/AdminMakeAttendanceImage",
        name: "AdminMakeAttendanceImage",
        component: () => import("@/components/admin/imageGenerator/AdminMakeAttendanceImage.vue"),
    },
    {
        path: "/AdminMakePhotoCardAll",
        name: "AdminMakePhotoCardAll",
        component: () => import("@/components/admin/imageGenerator/AdminMakePhotoCardAll.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        // 모바일 페이지일 경우 스크롤 위치를 맨 위로 이동
        if (to.path.includes("/MobileAdmin")) {
            document.querySelector("#view-container")?.scrollTo({ top: 0 });
            // return {
            //     el: "#view-container",
            //     top: 0,
            // };
        }
    }
});

export default router;
