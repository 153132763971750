import {
    ref
} from 'vue'
import {
    defineStore
} from 'pinia'

export const useFitnessConfigStore = defineStore('fitnessConfigStore', () => {
    const storeId = ref(1);
    const signalRConfigForMediaPlayer = {
        locationCode: 'STRETCH', 
        // 기존: STRETCH, 실서버 테스트용: STRETCH_TEST
        useCode: 'DEVICE',
        isAdmin: false,
    }
    const groupID = `${storeId.value}_${signalRConfigForMediaPlayer.locationCode}`

    return {
        storeId,
        signalRConfigForMediaPlayer,
        groupID
    }
})


