export default {
    buildMode: "dev",
    diaryApiBaseUrl: "https://testdiaryapi.learnbody.co.kr/",
    diaryReportApiBaseUrl: "https://linux-diary-report-api-slot.azurewebsites.net/",
    guideApiBaseUrl: "https://testguideapi.learnbody.co.kr/",
    marketApiBaseUrl: "https://testmarketapi.learnbody.co.kr/",
    marketStorageUrl: "https://learnbodykr.blob.core.windows.net/test-diary-images/",
    cdnUrl: "https://learnbodykr.blob.core.windows.net/cdn/",
    storageDIR: "test-diary-user-photo",
    shopByBaseUrl: "https://shop-api.e-ncp.com/",
    shopByClientId: "AXB0u3OIkGxA469WZ9GGmQ==",
    communityDomain: "https://testcommunityapp.learnbody.co.kr/",
    marketDomain: "https://testmarket.learnbody.co.kr",
    guideDomain: "https://testguide.azurewebsites.net",
    fitnessApiBaseUrl: "https://testfitnessapi.learnbody.co.kr/", // 테섭 1
    // fitnessApiBaseUrl: "https://testfitnessapi3.learnbody.co.kr/", // 테섭 2
    fitnessSignalRUrl: "https://testfitnesssignalhub.learnbody.co.kr/",
    fitnessProfileStorageUrl: "test-user-fitness-profile",
    scheduleApiBaseUrl: "https://testschedule.learnbody.co.kr/",
};
