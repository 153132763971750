import cookies from "js-cookie";
// 싱글톤 인스턴스를 저장할 변수
let instance = null;

// signalR 함수 정의
const KEYS_SIGNALR = {
    ADD_TO_GROUP: "AddToGroup", // 로그인 시 그룹에 추가
    RECEIVE_MESSAGE: "ReceiveMessage", // 로그인 정보 혹은 그룹 메시지 수신
    REMOVE_FROM_GROUP: "RemoveFromGroup", // 로그아웃 시 그룹에서 제거
    REMOVE_CONNECT: "RemoveConnect", // 로그 아웃 시 연결 해제
    SEND_MESSAGE_TO_GROUP: "SendMessageToGroup", // 그룹 메시지 전송
    SEND_MESSAGE_TO_CLIENT: "SendMessageToClient", // 개인 메시지 전송
    RECEIVE_DIRECT_MESSAGE: "ReceiveDirectMessage", // 개인 메시지 수신
    ADMIN_CALL_STORE_GROUPS: "AdminCallStoreGroups", // 그룹 정보 요청
    RECEIVE_ADMIN_GROUP_INFO: "ReceiveAdminGroupInfo", // 그룹 정보 수신
};

const KEYS_ETC = {
    PLAYER_PAGE_REFRESH_INFO: "PlayerPageRefreshInfo", // 플레이어 페이지 정보 갱신 시 필요한 값
    ADMIN_REFRESH_INFO: "AdminRefreshInfo", // 모바일 이벤트가 왔을 경우 관리자 페이지 정보 갱신 시 필요한 값
};

const KEYS_ADMIN = {
    ENCRYPT_ADMIN_ID: "encryptAdminId", // 플레이어 페이지 정보 갱신 시 필요한 값
    SIGNIN_KEY: "AdminSignInKey", // 관리자 로그인 성공 후 받은 키
    ADMIN_USER_INFO: "AdminUserInfo", // 관리자 정보
    ADMIN_USER_ID: "AdminUserID", // 관리자 로그인아이디
    ADMIN_USER_PWD: "AdminUserPWD", // 관리자 비밀번호
};

const KEYS_LOCATION_CODE = {
    ADMIN_PC: "ADMIN_PC", // 웹 ADMIN_PC
    COACH_APP: "COACH_APP", // 모바일 COACH_APP
}

/**
 * 데이터 센터 키
 */
// 시스템 데이터 키값
const KEYS_SYSTEM = {
    APP_TYPE: "SYSTEM_APP_TYPE", // 앱 타입 'Android' : 안드로이드, 'IOS' : 아이폰
    APP_VERSION: "SYSTEM_APP_VERSION", // 앱 버전 '1.0.0'
    APP_OS_VERSION: "SYSTEM_APP_OS_VERSION", // 앱 OS 버전 '14.0.0'
    LANGUAGE_CODE: "SYSTEM_LANGUAGE_CODE", // 언어 'ko' : 한국어, 'en' : 영어
    COUNTRY_CODE: "SYSTEM_COUNTRY_CODE", // 국가 '82' : 한국, '1' : 미국
    UTC_INTERVAL: "SYSTEM_UTC_INTERVAL", // UTC 시간차 -540 : 한국, -420 : 미국
    CAMERA_PERMISSION: "SYSTEM_CAMERA_PERMISSION", // 카메라 권한 허용 여부 'NOT_YET', 'GRANTED' : 허용, 'DENIED' : 비허용
    PUSH_PERMISSION: "SYSTEM_PUSH_PERMISSION", // 푸시 권한 허용 여부 'NOT_YET', 'GRANTED' : 허용, 'DENIED' : 비허용
};

/**
 * 함수
 */

/**
 * 유효기간 1년으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForYear(key, value) {
    // App 정보는 만료시간 360일 후 설정
    const expAppInfo = new Date();
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12);
    cookies.set(key, value, { expires: expAppInfo });
}

/**
 * 유효기간 x일으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForDays(key, value, day) {
    // App 정보는 만료시간 day일 후 설정
    const expAppInfo = new Date();
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * day);
    cookies.set(key, value, { expires: expAppInfo });
}

/**
 * 유효기간 1일으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForDay(key, value) {
    // App 정보는 만료시간 1일 후 설정
    const expAppInfo = new Date();
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24);
    cookies.set(key, value, { expires: expAppInfo });
}

/**
 * 유효기간 x시간으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
function setCookieForHour(key, value, time) {
    // App 정보는 만료시간 x시간 후 설정
    const expAppInfo = new Date();
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * time);
    cookies.set(key, value, { expires: expAppInfo });
}

/**
 * key값에 해당하는 쿠키값을 가져온다.
 * @param { String } key KEYS 객체의 키값
 * @returns
 */
function getCookie(key) {
    return cookies.get(key);
}

/**
 * key값에 해당하는 쿠키값을 삭제한다.
 * @param { String } key KEYS 객체의 키값
 */
function deleteCookie(key) {
    cookies.remove(key);
}

export function useDataCenter() {
    if (!instance) {
        instance = {
            KEYS_SIGNALR,
            KEYS_ETC,
            KEYS_ADMIN,
            setCookieForYear,
            setCookieForDays,
            setCookieForDay,
            setCookieForHour,
            getCookie,
            deleteCookie,
            KEYS_SYSTEM,
            KEYS_LOCATION_CODE,
        };
    }
    return instance;
}
