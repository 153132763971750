import cookies from "js-cookie";
let config = {};

if (process.env.BUILD_ENV === "prod") {
    config = require("./prod").default;
}
if (process.env.BUILD_ENV === "dev") {
    config = require("./dev").default;
}
if (process.env.BUILD_ENV === "slot") {
    config = require("./slot").default;
}

config.platform = function () {
    if (process.env.BUILD_TARGET === "mobileweb") {
        return "MOBILE_WEB";
    }
    const ostype = cookies.get("AppType");
    if (ostype === "AOS") {
        return "AOS";
    }
    return "IOS";
};

config.appStoreUrlForIOS = "http://itunes.apple.com/app/inbody/id884923678";
config.appStoreUrlForAOS = "market://details?id=com.inbody2014.inbody";

// Local 개발시 플래그 활성화
config.isLocalDevelopment = false;

// stores/signalRConfig.js에서 storeId 확인 후 배포
export default config;