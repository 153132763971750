<template>
    <div>
        <router-view />
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useDataCenter } from "@/managers/dataCenter";
import {
    SetHandlerPushPermission,
    UpdatePushPermission,
    SetHandlerPushInfo,
    SetPushInfo,
} from "@/managers/publicMethod";
import { useAdminBaseInfoStore } from "@/stores/adminBaseInfo";

const router = useRouter();
const dataCenter = useDataCenter();
const adminBaseInfo = useAdminBaseInfoStore();

// 푸시 랜딩 정보 처리
const handlerSetPushInfo = (data) => {
    console.log("handlerSetPushInfo");
    console.log(data.ViewCode + " / " + data.LandingCode + " / " + data.LandingData);

    // 푸시 랜딩 정보 처리
    if (data.ViewCode == "1") {
        if (data.LandingCode == "SUNNYUP_COACH_TAB_CHANGE") {
            if (data.LandingData === "ATTENDANCE") {
                router.push("/MobileAdmin");
            } else if (data.LandingData === "NOTIFICATION") {
                router.push("/NotificationPage");
            } else if (data.LandingData === "TODO") {
                router.push("/TodoPage");
            }
        }
    }
};

onBeforeMount(() => {
    // 모바일 앱 페이지인경우 
    if (window.location.href.toLowerCase().indexOf("/mobileadmin") > -1) {
        const urlParams = new URLSearchParams(window.location.search);
        // let uid = urlParams.get('uId')
        // if (uid !== undefined && uid !== null) setCookieForYear('UID', uid)
        let appType = urlParams.get("appType");
        if (appType !== undefined && appType !== null)
            dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, appType);
        let appVersion = urlParams.get("appVersion");
        if (appVersion !== undefined && appVersion !== null)
            dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, appVersion);
        let osVersion = urlParams.get("osVersion");
        if (osVersion !== undefined && osVersion !== null)
            dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, osVersion);
        let language = urlParams.get("language");
        if (language !== undefined && language !== null)
            dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, language);
        let country = urlParams.get("country");
        if (country !== undefined && country !== null)
            dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, country);

        // let languageCode = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE);
        // t.locale = languageCode === undefined ? "ko" : languageCode;

        let utcInterval = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.UTC_INTERVAL);
        if (utcInterval === undefined || utcInterval === null) {
            dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540);
        }
    }
});

const setBaseInfoLocationCode = () => {
    if (window.location.href.toLowerCase().indexOf("/mobileadmin") > -1) {
        adminBaseInfo.defaultParams.locationCode = dataCenter.KEYS_LOCATION_CODE.COACH_APP;
    } else {
        adminBaseInfo.defaultParams.locationCode = dataCenter.KEYS_LOCATION_CODE.ADMIN_PC;
    }
};

onMounted(() => {
    // 모바일 앱 페이지인경우
    if (window.location.href.toLowerCase().indexOf("/mobileadmin") > -1) {
        SetHandlerPushPermission(null);
        window.UpdatePushPermission = UpdatePushPermission;
        SetHandlerPushInfo(handlerSetPushInfo);
        window.SetPushInfo = SetPushInfo;
        // body 복사방지 CSS를 위한 클래스
        document.body.classList.add("mobile");
    }
    setBaseInfoLocationCode();
});
</script>

<style>
@import "./assets/css/normalization.css";
@import "@/assets/css/iconImage.css";

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
