import { reactive, watchEffect } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { useFitnessConfigStore } from "@/stores/signalRConfig";
import { useAdminUserInfoStore } from "@/stores/adminUserInfo";
import { useDataCenter } from "@/managers/dataCenter";

export const useAdminBaseInfoStore = defineStore("AdminBaseInfo", () => {
    const dataCenter = useDataCenter();
    const fitnessStore = useFitnessConfigStore();

    let defaultParams = reactive({
        encryptAdminId: dataCenter.getCookie(dataCenter.KEYS_ADMIN.ENCRYPT_ADMIN_ID),
        storeId: fitnessStore.storeId,
        language: "ko",
        country: "82",
        utcInterval: -540,
        adminUserCertificateKey: dataCenter.getCookie(dataCenter.KEYS_ADMIN.SIGNIN_KEY),
        locationCode: "",
    });

    const adminUserInfoStore = useAdminUserInfoStore();
    const { loggedInUser } = storeToRefs(adminUserInfoStore);

    watchEffect(() => {
        // 관리자 정보 변경에 따라 기본 파라미터 변경
        const currentValue = loggedInUser.value?.encryptAdminId;
        if (currentValue && currentValue !== defaultParams.encryptAdminId) {
            defaultParams.encryptAdminId = currentValue;
        }
    });

    const setInitUserCertificateKey = () => {
        defaultParams.encryptAdminId = dataCenter.getCookie(dataCenter.KEYS_ADMIN.ENCRYPT_ADMIN_ID);
    }

    setInitUserCertificateKey();

    return {
        defaultParams,
    };
});
