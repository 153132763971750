import { reactive, ref } from "vue";
import { defineStore } from "pinia";

import { requestFitness } from "@/api";
import { useFitnessConfigStore } from "@/stores/signalRConfig";
import { useDataCenter } from "@/managers/dataCenter";

export const useAdminUserInfoStore = defineStore("adminUserInfo", () => {
    const dataCenter = useDataCenter();
    const fitnessStore = useFitnessConfigStore();

    const defaultParams = reactive({
        storeId: fitnessStore.storeId,
        language: "ko",
        country: "82",
        utcInterval: -540,
        adminUserCertificateKey: dataCenter.getCookie(dataCenter.KEYS_ADMIN.SIGNIN_KEY),
    });
    const endPoint = reactive({
        SETTING_ADMIN_STORE_USERLIST: "Admin/SettingAdminStoreUserList",
    });

    const adminList = ref(null);
    const loggedInUser = ref(null);

    async function getAdminList() {
        let adminUserCertificateKey = dataCenter.getCookie(dataCenter.KEYS_ADMIN.SIGNIN_KEY);
        adminUserCertificateKey = adminUserCertificateKey ? adminUserCertificateKey : "";
        const resp = await requestFitness(`${endPoint.SETTING_ADMIN_STORE_USERLIST}`, "post", {
            ...defaultParams,
            adminUserCertificateKey: adminUserCertificateKey,
        });

        if (resp.data && resp.data.status !== 400 && resp.data.isOk) {
            adminList.value = resp.data.administrators;
            const savedCookieData = dataCenter.getCookie(dataCenter.KEYS_ADMIN.ENCRYPT_ADMIN_ID);

            if (savedCookieData) {
                const filteredAdmin = adminList.value.filter((item) => item.encryptAdminId === savedCookieData);

                // 저장된 쿠키값에 encryptAdminId가 존재하면 해당 유저 정보를 저장
                if (filteredAdmin.length > 0) {
                    loggedInUser.value = filteredAdmin[0];
                } else {
                    // 쿠키값이 존재하지 않으면 첫 번째 유저 정보 저장
                    loggedInUser.value = adminList.value[0];
                }
            } else {
                dataCenter.setCookieForYear(dataCenter.KEYS_ADMIN.ENCRYPT_ADMIN_ID, adminList.value[0].encryptAdminId);
                loggedInUser.value = adminList.value[0];
            }
        }
        return resp.data;
    }

    function updateLoggedInUser(encryptAdminId) {
        const filteredAdmin = adminList.value.filter((item) => item.encryptAdminId === encryptAdminId);

        if (filteredAdmin.length > 0) {
            loggedInUser.value = filteredAdmin[0];
            dataCenter.setCookieForYear(dataCenter.KEYS_ADMIN.ENCRYPT_ADMIN_ID, encryptAdminId);
        }
    }

    function setLoggedInUserInfo(infoData) {
        // 모바일 앱에서 로그인 시 사용자 정보 저장
        loggedInUser.value = infoData;
    }

    return {
        getAdminList,
        adminList,
        loggedInUser,
        updateLoggedInUser,
        setLoggedInUserInfo,
    };
});
